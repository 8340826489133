import StatusPage from './components/Pages/Status'
import { AuthProvider } from './hooks/useAuth'
import Header from './components/Layout/Header'
import { Row, Col } from 'antd'

const App = () => {
  return (
    <AuthProvider>
      <Header />
      <Row guter={[16, 16]}>
        <Col xs={24} xl={{ span: 12, offset: 6 }}>
          <StatusPage />
        </Col>
      </Row>
    </AuthProvider>
  )
}

export default App
