import { useState, useEffect, useContext, createContext } from 'react'
import OpenLogin from '@toruslabs/openlogin'

const openlogin = new OpenLogin({
  clientId: process.env.REACT_APP_TORUS_PROJECT_ID,
  network: process.env.REACT_APP_TORUS_NETWORK
})

const AuthContext = createContext()

export const useAuth = () => {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }

  return context
}

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState()
  const [signedIn, setSignedIn] = useState(false)
  const [loading, setLoading] = useState(false)

  const autoLogin = async function () {
    await openlogin.init()
    if (openlogin.privKey) {
      return setUser(openlogin)
    }
    return setUser()
  }

  async function handleLogin () {
    setLoading(true)
    try {
      await openlogin.login({
        fastLogin: true,
        extraLoginOptions: {
        }
      })
      setUser(openlogin)
      setLoading(false)
    } catch (error) {
      console.log(`Could not login user: ${error.message}`)
      setLoading(false)
    }
  }

  async function handleLogout () {
    setLoading(true)
    await openlogin.logout()
    setLoading(false)
  }

  useEffect(() => autoLogin(), [])
  useEffect(() => setSignedIn(!!user?.privKey), [user])

  const value = {
    signedIn,
    user,
    login: handleLogin,
    loading,
    logout: handleLogout
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
