import { useEffect, useState } from 'react'
import { Typography, Card, Descriptions, Tooltip, Tag, Row, Col } from 'antd'
import { CheckCircleOutlined as IconOk, ExclamationCircleOutlined as IconWarning, SyncOutlined as IconProcessing } from '@ant-design/icons'
import { ethers } from '@vechain/ethers'

import Connex from '@vechain/connex'

const CONNEX_NETWORK_URL = process.env.REACT_APP_CONNEX_NETWORK_URL
const CONNEX_NETWORK_TYPE = process.env.REACT_APP_CONNEX_NETWORK_TYPE

const connex = new Connex({
  node: CONNEX_NETWORK_URL,
  network: CONNEX_NETWORK_TYPE
})

const { Title } = Typography
export default function StatusPage () {
  const [blockchainStatus, setBlockchainStatus] = useState({ pending: true })
  const [adminStatus, setAdminStatus] = useState({ pending: true })
  const [votingStatus, setVotingStatus] = useState({ pending: true })
  const [pinataStatus, setPinataStatus] = useState({ pending: true })
  const [ipfsGatewayStatus, setIpfsGatewayStatus] = useState({ pending: true })
  const [ipfsProxyStatus, setIpfsProxyStatus] = useState({ pending: true })
  const [vechainEnergyStatus, setVechainEnergyStatus] = useState({ pending: true })
  const [vechainEnergyBalanceStatus, setVechainEnergyBalanceStatus] = useState({ pending: true })
  const [web3AuthStatus, setWeb3AuthStatus] = useState({ pending: true })
  const [voteosContractStatus, setVoteosContractStatus] = useState({ pending: true })

  useEffect(() => {
    testConnex()
    testAdmin('https://admin.voteos.com')
    testVoting('https://vote.voteos.com')
    testPinata()
    testIpfsGateway()
    testIpfsProxy()
    testVechainEnergy()
    testVechainEnergyBalance()
    testWeb3Auth()
    testVoteosContract()
  }, [])

  async function testConnex () {
    try {
      const lastBlock = new Date(connex.thor.status.head.timestamp * 1000)
      const now = new Date()
      if ((lastBlock - now) > 30) {
        return setBlockchainStatus({
          ok: false,
          error: 'Out of Sync'
        })
      }
      setBlockchainStatus({ ok: true })
    } catch (err) {
      setBlockchainStatus({
        ok: false,
        error: err.message
      })
    }
  }

  async function testAdmin (url) {
    try {
      await window.fetch(url)
      setAdminStatus({ ok: true })
    } catch (err) {
      setAdminStatus({
        ok: false,
        error: err.message
      })
    }
  }

  async function testVoting (url) {
    try {
      await window.fetch(url)
      setVotingStatus({ ok: true })
    } catch (err) {
      setVotingStatus({
        ok: false,
        error: err.message
      })
    }
  }

  async function testPinata () {
    try {
      const res = await window.fetch('https://api.pinata.cloud')
      const json = await res.json()
      if (json && json?.error?.reason === 'INVALID_ROUTE') {
        return setPinataStatus({ ok: true })
      }

      return setPinataStatus({ ok: false, error: json?.error })
    } catch (err) {
      setPinataStatus({
        ok: false,
        error: err.message
      })
    }
  }

  async function testIpfsGateway () {
    const url = 'https://voteos.mypinata.cloud/ipfs/QmYGSaKuqKQgSicCgNfqj4PDvJ6q3Lb1P5vqMjPYAcjad4'
    try {
      const res = await window.fetch(url)
      const json = await res.json()
      if (json && json.title) {
        return setIpfsGatewayStatus({ ok: true })
      }

      return setIpfsGatewayStatus({ ok: false, error: 'format does not match' })
    } catch (err) {
      setIpfsGatewayStatus({
        ok: false,
        error: err.message
      })
    }
  }

  async function testIpfsProxy () {
    const url = 'https://api.voteos.com/public/QmYGSaKuqKQgSicCgNfqj4PDvJ6q3Lb1P5vqMjPYAcjad4'
    try {
      const res = await window.fetch(url)
      const json = await res.json()
      if (json && json.title) {
        return setIpfsProxyStatus({ ok: true })
      }

      return setIpfsProxyStatus({ ok: false, error: 'format does not match' })
    } catch (err) {
      setIpfsProxyStatus({
        ok: false,
        error: err.message
      })
    }
  }

  async function testVechainEnergy () {
    const url = 'https://api.voteos.com/fee/delegate'
    try {
      const res = await window.fetch(url, { method: 'POST', body: '{}', headers: { 'Content-Type': 'application/json' } })
      const json = await res.json()
      if (json?.message === 'required parameters missing: raw, origin') {
        return setVechainEnergyStatus({ ok: true })
      }

      return setVechainEnergyStatus({ ok: false, error: 'format does not match' })
    } catch (err) {
      setVechainEnergyStatus({
        ok: false,
        error: err.message
      })
    }
  }

  async function testVechainEnergyBalance () {
    try {
      const account = '0x60ae4cccdf8f02fd6a76356d9bfc8050a0c0e660'
      const { energy } = await connex.thor.account(account).get()

      const rawVtho = new ethers.utils.BigNumber(energy)
      const vtho = Number(ethers.utils.formatEther(rawVtho))

      if (vtho > 10000) {
        return setVechainEnergyBalanceStatus({ ok: true, info: `${vtho.toFixed(0)} Balance` })
      }

      return setVechainEnergyBalanceStatus({ ok: false, error: 'low balance' })
    } catch (err) {
      setVechainEnergyBalanceStatus({
        ok: false,
        error: err.message
      })
    }
  }

  async function testWeb3Auth () {
    setWeb3AuthStatus({ ok: false, error: 'implementation missing' })
  }

  async function testVoteosContract () {
    const address = '0x555fe0e4895518e845b87cabcab6426fc1e94e2d'
    try {
      const { hasCode } = await connex.thor.account(address).get()

      if (hasCode) {
        return setVoteosContractStatus({ ok: true })
      }

      return setVoteosContractStatus({ ok: false, error: 'address is no contract' })
    } catch (err) {
      setVoteosContractStatus({
        ok: false,
        error: err.message
      })
    }
  }

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} xl={{ span: 18, offset: 3 }} />
      <Col xs={24} xl={{ span: 18, offset: 3 }}><Title>Status</Title></Col>
      <Col xs={24} xl={{ span: 18, offset: 3 }}>
        <Card bordered={false} hoverable size='small'>
          <Descriptions bordered column={{ xs: 1, xl: 2 }} contentStyle={{ textAlign: 'right' }} size='default'>
            <Descriptions.Item label='Administration-Website'> <StatusTag {...adminStatus} /></Descriptions.Item>
            <Descriptions.Item label='Voting-Website'> <StatusTag {...votingStatus} /></Descriptions.Item>
            <Descriptions.Item label='IPFS Gateway'> <StatusTag {...ipfsGatewayStatus} /></Descriptions.Item>
            <Descriptions.Item label='IPFS Proxy'> <StatusTag {...ipfsProxyStatus} /></Descriptions.Item>
            <Descriptions.Item label='Node'> <StatusTag {...blockchainStatus} /></Descriptions.Item>
            <Descriptions.Item label='voteos-Contract'> <StatusTag {...voteosContractStatus} /></Descriptions.Item>
            <Descriptions.Item label='web3auth-Service'> <StatusTag {...web3AuthStatus} /></Descriptions.Item>
            <Descriptions.Item label='Pinata IPFS-Service'> <StatusTag {...pinataStatus} /></Descriptions.Item>
            <Descriptions.Item label='vechain.energy-Service'> <StatusTag {...vechainEnergyStatus} /></Descriptions.Item>
            <Descriptions.Item label='vechain.energy Balance'> <StatusTag {...vechainEnergyBalanceStatus} /></Descriptions.Item>
          </Descriptions>
        </Card>
      </Col>
    </Row>

  )
}

function StatusTag ({ ok, pending, error, info }) {
  if (pending) {
    return <Tag color='processing' icon={<IconProcessing spin />}>Loading</Tag>
  }

  if (ok && info) {
    return <Tooltip title={info}><Tag color='success' icon={<IconOk />}>OK</Tag></Tooltip>
  }

  if (ok) {
    return <Tag color='success' icon={<IconOk />}>OK</Tag>
  }

  return <Tooltip title={error}><Tag color='error' icon={<IconWarning />}>NOK</Tag></Tooltip>
}
