import React from 'react'
import styled from 'styled-components'
import { Layout } from 'antd'
import Logo from './Logo'

const { Header } = Layout

const StyledHeader = styled(Header)`
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export default function LayoutHeader ({ children, ...restProps }) {
  return (
    <StyledHeader {...restProps}>
      <Logo />
      {children}
    </StyledHeader>
  )
}
