import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Typography } from 'antd'
import { Link } from 'wouter'
import { ReactComponent as Logo } from './Logo.svg'

const { Text } = Typography

const LogoContainer = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const LogoText = styled(Text)`
  font-size: 14px;
  color: #fff;
`

const StyledLogo = styled(Logo)`
  width: 30px;
  margin-right: 10px;
`

export default function HeaderLogo () {
  const { t } = useTranslation()
  return (
    <LogoContainer href='/'>
      <StyledLogo />
      <LogoText>{t('appname').toUpperCase()}</LogoText>
    </LogoContainer>
  )
}
